$roboto-regular: 'Roboto-Regular', sans-serif;
$roboto-medium: 'Roboto-Medium', sans-serif;
$roboto-bold: 'Roboto-Bold', sans-serif;
$black: #000000;
$blue: #2c93f3;
$white: #ffffff;
$green: #5ebc7c;
$red: #d0021b;
$light-gray: #979797;
$gray: #e9e9e9;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-28: 28px;
$container-width: 1263px;
$page-min-height: 1014px;
$input-box-shadow: 0 2px 4px 0 rgba(213, 213, 213, 0.28);
$label-color: #a5a5a5;
$form-error: #f44336;
$panel-title-left: 58px;
$footer-divider: rgb(223, 229, 235);
$footer-height: 62px;
